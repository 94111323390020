.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  color: #ffffff;
  padding: 5px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header-title {
  font-size: 24px;
  margin-bottom: 5px;
}

a { 
  text-decoration: none;
  color: white; 
 }

.header-links {
  display: flex;
  flex-direction: flex;
  padding: 5px;
  align-items: center;
}

.header-links a {
  margin: 5px 0;
  color: #ffffff;
  padding: 5px 10px;
  text-decoration: none;
}

.header-dropdown {
  position: relative;
  cursor: pointer;
  padding: 5px 10px;
  color: #ffffff;
}

.header-dropdown-content {
  display: none;
  position: absolute;
  background-color: #121212;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content a {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.header-dropdown-content a:hover {
  background-color: #343434;
}
