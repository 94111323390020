.about-page {
  flex: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  }

.about-container {
  max-width: 75%;
  border: 2px solid #333;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  }
  
  .about-container h2 {
    font-size: 32px;
    max-width: 70%;
    margin-top: 20px;
    color: #ffffff;
  }
  
  .about-container p {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
    max-width: 50%;
    text-align: center;
    color: #dddddd;
  }