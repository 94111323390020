body {
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tom-comp-chart {
  max-width: 100%; /* Ensure the image doesn't exceed the container's width */
  height: auto; /* Maintain the image's aspect ratio */
}

@media (max-width: 767px) {
  .tom-comp-chart {
    width: 100%; /* Adjust the image to 100% width on smaller screens */
  }
}

.top-container {
  width: 65%;
  border: 2px solid #333;
  background-color: #1f1f1f;
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tierlist-container {
  border: 2px solid #333;
  background-color: #1f1f1f;
  max-width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blacklist-container {
  width: 55%;
  text-align: center;
  border: 2px solid #333;
  background-color: #1f1f1f;
  padding: 10px;
  margin-top: 30px;
  border-radius: 8px;
}

.blacklist-container:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.blacklist-note {
  font-style: italic;
  color: #777;
}

.tom-spark-container {
  width: 75%;
  background-color: #1f1f1f;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.tom-spark-container :hover {
  background-color: #444;
}

.tom-spark-container h3:hover {
  text-decoration: underline;
}

.tom-spark-container h3 {
  font-size: 1.5em;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  margin: auto;
}

.tom-spark-container p {
  margin: 0;
  font-size: 1.1em;
  color: #ccc;
  line-height: 1.5;
  text-align: center;
}

.tom-spark-container p a {
  color: #66BFFF;
  text-decoration: none;
  transition: color 0.3s;
}

.tom-spark-container p a:hover {
  color: #7AA4FF;
  text-decoration: underline;
}

.discord-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.tier-list {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(45deg, #121212, #292929, #1f1f1f, #292929);
  background-size: 400% 400%;
  color: #fff;
  animation: background-animation 15s linear infinite;
}

.intro-text {
  font-size: x-large;
  width: 50%;
  margin: 20px;
  text-align: center;
}
  
  .tiers-container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .tier {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border: 1px solid #333;
    background-color: #1f1f1f;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    height: 585px;
    width: 200px;
  }
  
  .tier:hover {
    transform: translateY(-1px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .tier h2 {
    margin: 0;
    font-size: 24px;
    color: #fff;
  }
  
  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .vpn-item {
    padding: 10px;
    border: 1px solid #333;
    background-color: #241b1b;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    transition: background-color 0.3s;
    height: 25px;
  }
  
  .vpn-item:hover {
    background-color: #444;
  }

  .updates-container {
    width: 80%;
    background-color: #1f1f1f;
    border: 2px solid #333;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }

  .updates-container h3 {
    font-size: 1.3em;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .updates-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .updates-container li {
    font-size: 1.1em;
    margin-bottom: 8px;
    color: #ccc;
  }
  
  .updates-container ul ul {
    list-style-type: circle;
    margin-left: 20px;
  }

  @keyframes background-animation {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 100% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }